import { Injectable } from '@angular/core';
import { Observable, Observer, BehaviorSubject, of, Subject, forkJoin } from 'rxjs';
import { OfflineZipDownloadService } from './offline-zip-download.service';
import { DocumentTreesService } from './dexie/document-trees.service';
import { DocumentService } from './dexie/document.service';
import { DmService } from './dexie/dm.service';
import { IcnService } from './dexie/icn.service';
import { PmcService } from './dexie/pmc.service';
import { IndexRoot, IndexTree, IndexDoc, IndexDm, IndexIcn, IndexPmIcn, IndexPm } from '../../data-model/offline';
import { DocumentTree, DocumentNode, Dm, Icn, GlobalData, OfflineCount } from '../../data-model/idb';
import { GlobalDataService } from './dexie/global-data.service';
import { Pmc, PmIcn } from '../../data-model/idb/pmc';
import { OfflineStatusService } from './offline-status.service';
import { OfflineFileService } from './offline-file.service';
import { DataService } from '../data-services/data.service';
import { TpubDbService } from './dexie/tpub-db.service';
import { DocType } from '../../enums/doc-types';
import { DownloadFileStatus } from './download/DownloadFileStatus';
import { DownloadDocStatus } from './download/DownloadDocStatus';
import { getNowUTC } from '../../utils/date-util';

@Injectable({
  providedIn: 'root'
})
export class OfflineFeedService {

  public totalCalls = 0;
  public currentCalls = 0;
  private documentNodes: DocumentNode[] = [];
  private icns: Icn[] = [];
  public selectedDownloadTrees;
  public cancelUpdateStarted = false;
  private _isActivated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private offlineCount: OfflineCount;
  private allDocumentTrees: DocumentTree[] = [];
  private allDocuments: DocumentNode[] = [];
  private allDms: Dm[] = [];
  private allPms: Pmc[] = [];
  private allIcns: Icn[] = [];
  private downloadFileStatuses: DownloadFileStatus[] = [];
  private downloadDocStatuses: DownloadDocStatus[] = [];
  private removeFileStatuses: DownloadFileStatus[] = [];
  private removeDocStatuses: DownloadDocStatus[] = [];
  public errorDownloadingDoc$ = new Subject<any>();
  private completedDocsInSync: string[] = [];

  constructor(
    private documentTreesService: DocumentTreesService,
    private documentService: DocumentService,
    private dmService: DmService,
    private icnService: IcnService,
    private pmcService: PmcService,
    private dataService: DataService,
    private globalDataService: GlobalDataService,
    private offlineZipDownloadService: OfflineZipDownloadService,
    private offlineStatusService: OfflineStatusService,
    private offlineFileService: OfflineFileService,
    private tpubDbService: TpubDbService,
  ) { }

  get isOfflineActivated(): BehaviorSubject<boolean> {
    return this._isActivated;
  }

  activate() {
    this.totalCalls = 0;
    this.currentCalls = 0;
    this.getUserProducts();
    this.getAllModels();
    this.getIndex();
    this.getInitialZipFiles();
  }

  syncContent(offlineCount: OfflineCount) {
    this.totalCalls = 0;
    this.currentCalls = 0;
    this.totalCalls = this.totalCalls + 1;
    this.icns = [];
    this.documentNodes = [];
    this.cancelUpdateStarted = false;
    this.offlineCount = offlineCount;
    this.cancelUpdateStarted = false;
    this.completedDocsInSync = [];

    const documentTrees$ = this.documentTreesService.getAll();
    const documents$ = this.documentService.getAll();
    const pms$ = this.pmcService.getAll();
    const dms$ = this.dmService.getAll();
    const icn$ = this.icnService.getAll();

    forkJoin([documentTrees$, documents$, pms$, dms$, icn$])
      .subscribe(([documentTrees, documents, pms, dms, icns]) => {
        this.allDocumentTrees = documentTrees;
        this.allDocuments = documents;
        this.allDms = dms;
        this.allPms = pms;
        this.allIcns = icns;
        this.downloadFileStatuses = [];
        this.downloadDocStatuses = [];
        this.removeFileStatuses = [];
        this.removeDocStatuses = [];
        let trees = this.allDocumentTrees.filter(dt => dt.download === 'true');
        const updateNeededDocStrings: string[] = [];
        const downloadedDocStrings = this.allDocumentTrees.filter((d) => d.usable === 'true' && d.doc && d.doc.length > 0).map(d => d.doc);
        if (downloadedDocStrings.length > 0) {
          const updatedNeededDocs = this.allDocuments.filter(d => downloadedDocStrings.indexOf(d.name) > -1);
          if (updatedNeededDocs.length > 0) {
            updatedNeededDocs.forEach(d => {
              if (getNowUTC(new Date(d.availableUpdate)) > d.lastUpdate) {
                updateNeededDocStrings.push(d.name);
              }
            });
          }
          if (updateNeededDocStrings.length > 0) {
            const updateNeededTrees = this.allDocumentTrees.filter(t => updateNeededDocStrings.indexOf(t.doc) > -1);
            trees.push(...updateNeededTrees);
          }
        }
        if (trees && trees.length > 0) {
          this.selectedDownloadTrees = trees;
          this.downloadContent(trees, updateNeededDocStrings).subscribe((result) => {
            if (!this.cancelUpdateStarted) {
              const removedTrees = this.allDocumentTrees.filter(dt => dt.remove === 'true');
              if (removedTrees && removedTrees.length > 0) {
                trees = trees.concat(removedTrees);
                this.removeContent(removedTrees).subscribe((removeResults) => {
                  this.getChangedDocsAndIcns();
                  this.offlineStatusService.setDownloadStaus(trees, this.documentNodes,
                    this.icns, this.allDocumentTrees).subscribe((ts) => {
                    this.currentCalls = this.currentCalls + 1;
                  });
                });
              } else {
                this.getChangedDocsAndIcns();
                this.offlineStatusService.setDownloadStaus(trees, this.documentNodes, this.icns, this.allDocumentTrees).subscribe((ts) => {
                  this.currentCalls = this.currentCalls + 1;
                });
              }
            } else {
              this.getChangedDocsAndIcns();
              this.offlineStatusService.setDownloadStaus(trees, this.documentNodes, this.icns, this.allDocumentTrees).subscribe((ts) => {
                this.currentCalls = this.currentCalls + 1;
              });
            }
          });
        } else {
          const removedTrees = this.allDocumentTrees.filter(dt => dt.remove === 'true');
          this.removeContent(removedTrees).subscribe((removeResults) => {
            if (removeResults === true) {
              this.getChangedDocsAndIcns();
              this.offlineStatusService.setDownloadStaus(removedTrees, this.documentNodes, this.icns,
                this.allDocumentTrees).subscribe((ts) => {
                  this.currentCalls = this.currentCalls + 1;
                });
            } else {
              this.currentCalls = this.currentCalls + 1;
            }
          });
        }
      });
  }

  getProgressNumber(): Observable<number> {
    return new Observable((observer: Observer<number>) => {

      if (this.currentCalls > this.totalCalls) {
        this.currentCalls = this.totalCalls;
      }

      console.log('PROGRESS: ', (this.currentCalls / this.totalCalls) * 100);
      observer.next((this.currentCalls / this.totalCalls) * 100);
      observer.complete();

      if (this.cancelUpdateStarted === true) {
        if (this.totalCalls > 1) {
          this.totalCalls = 1;
          this.currentCalls = 0;
        }
      }
    });
  }

  updateLastCheck(docsDate: Date = null): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      let dbUpdateObservable: Observable<IndexRoot>;
      if (docsDate === null) {
        dbUpdateObservable = this.dataService.getUpdateIndex(getNowUTC(new Date()), null, null, true);
      } else {
        dbUpdateObservable = of({ 'lastupdate': docsDate } as any);
      }
      const getKeyObservable = this.globalDataService.getByKey('lastCheck');
      forkJoin([dbUpdateObservable, getKeyObservable]).subscribe((results) => {
        if (!results[0].lastupdate) {
          // default to last 10 days if no date returned
          results[0].lastupdate = getNowUTC(new Date());
        }
        // convert string to date if needed
        results[0].lastupdate = getNowUTC(new Date(results[0].lastupdate));
        if (results[1]) {
          results[1].data = results[0].lastupdate;
          this.globalDataService.update(results[1].rowid, results[1]).subscribe(() => {
            this._isActivated.next(true);
            observer.next(true);
            observer.complete();
          });
        } else {
          this.globalDataService.add(new GlobalData('lastCheck', results[0].lastupdate)).subscribe(() => {
            this._isActivated.next(true);
            observer.next(true);
            observer.complete();
          });
        }
      });
    });
  }

  isActivated(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.globalDataService.getByKey('lastCheck').subscribe((result: GlobalData) => {
        if (result) {
          this._isActivated.next(true);
          observer.next(true);
          observer.complete();
        } else {
          this._isActivated.next(false);
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  deActivate(): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      this.tpubDbService.clear().subscribe(() => {
        this.offlineFileService.deleteUserDocumentDirectory().subscribe(() => {
          observer.next(true);
          observer.complete();
        });
      });
    });
  }

  cancelLibraryUpdate() {
    this.cancelUpdateStarted = true;
  }

  //#region get initial files
  private getInitialZipFiles() {
    this.totalCalls = this.totalCalls + 1;
    this.offlineZipDownloadService.getIndexes().subscribe((data) => {
      this.currentCalls = this.currentCalls + 1;
    });
    this.totalCalls = this.totalCalls + 1;
    this.offlineZipDownloadService.getFaultCodes().subscribe((data) => {
      this.currentCalls = this.currentCalls + 1;
    });
    this.totalCalls = this.totalCalls + 1;
    this.offlineZipDownloadService.getFleet().subscribe((data) => {
      this.currentCalls = this.currentCalls + 1;
    });
    this.totalCalls = this.totalCalls + 1;
    this.offlineZipDownloadService.getWiringData().subscribe((data) => {
      this.currentCalls = this.currentCalls + 1;
    });
    this.totalCalls = this.totalCalls + 1;
    this.offlineZipDownloadService.getToc().subscribe((data) => {
      this.currentCalls = this.currentCalls + 1;
    });
  }
  private getUserProducts() {
    this.totalCalls = this.totalCalls + 1;
    this.dataService.getUserProduct().subscribe((data) => {
      this.globalDataService.getByKey('userProduct').subscribe((result) => {
        if (!result) {
          this.globalDataService.add(new GlobalData('userProduct', data)).subscribe(() => {
            this.currentCalls = this.currentCalls + 1;
          });
        } else {
          result.data = data;
          this.globalDataService.update(result.rowid, result).subscribe(() => {
            this.currentCalls = this.currentCalls + 1;
          });
        }
      });
    });
  }

  private getAllModels() {
    this.totalCalls = this.totalCalls + 1;
    this.dataService.getRevisionStatus().subscribe((rs) => {
      this.globalDataService.getByKey('allModels').subscribe((result) => {
        if (!result){
          this.globalDataService.add(new GlobalData('allModels', rs.models)).subscribe(() => {
            this.currentCalls = this.currentCalls + 1;
          });
        } else {
          result.data = rs.models;
          this.globalDataService.update(result.rowid, result).subscribe(() => {
            this.currentCalls = this.currentCalls + 1;
          });
        }
      });
    });
  }
  //#endregion get intial files

  //#region  Initial Index
  private getIndex() {
    this.totalCalls = this.totalCalls + 1;
    this.dataService.getIndex().subscribe((data: IndexRoot) => {
      if (data) {
        const offlineFeeds$: Observable<boolean>[] = [];
        this.saveIndex(data).subscribe();
        offlineFeeds$.push(this.AddTrees(data));
        offlineFeeds$.push(this.AddDocuments(data));
        offlineFeeds$.push(this.AddDms(data));
        offlineFeeds$.push(this.AddIcns(data));
        offlineFeeds$.push(this.AddPmcs(data));
        forkJoin(offlineFeeds$).subscribe((feedStatuses: boolean[]) => {
          this.currentCalls = this.currentCalls + 1;
        });

      } else {
        this.currentCalls = this.currentCalls + 1;
      }
    });
  }

  private saveIndex(data: IndexRoot): Observable<boolean> {
    const fileContent = JSON.stringify(data);
    return this.offlineFileService.writeStringToFile('\\zip\\','toc', fileContent)
  }

  private AddTrees(data: IndexRoot): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data.tree) {
        this.documentTreesService.clear().subscribe(() => {
          const mappedTrees: DocumentTree[] = [];
          data.tree.forEach((tree: IndexTree) => {
            mappedTrees.push(this.mapIndexTree(tree));
          });
          if (mappedTrees.length > 0) {
            this.documentTreesService.addBulk(mappedTrees).subscribe((result: boolean) => {
              observer.next(result);
              observer.complete();
            });
          } else {
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private mapIndexTree(tree: IndexTree): DocumentTree {
    const mappedTree = new DocumentTree();
    mappedTree.path = tree.path;
    mappedTree.parent = tree.parent;
    mappedTree.childCount = tree.childCount;
    mappedTree.selected = 'false';
    mappedTree.selectedChild = 0;
    mappedTree.usable = 'false';
    mappedTree.usableChild = 0;
    mappedTree.updatable = 'false';
    mappedTree.updatableChild = 0;
    mappedTree.remove = 'false';
    mappedTree.removeChild = 0;
    mappedTree.download = 'false';
    mappedTree.downloadChild = 0;
    mappedTree.doc = tree.doc;
    mappedTree.pendingAction = 'false';
    mappedTree.lastAction = undefined;
    return mappedTree;
  }

  private AddDocuments(data: IndexRoot): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data.docs) {
        this.documentService.clear().subscribe(() => {
          const mappedDocs: DocumentNode[] = [];
          data.docs.forEach((doc: IndexDoc) => {
            mappedDocs.push(this.mapIndexDocumentNode(doc));
          });
          if (mappedDocs.length > 0) {
            this.documentService.addBulk(mappedDocs).subscribe((result: boolean) => {
              observer.next(result);
              observer.complete();
            });
          } else {
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private mapIndexDocumentNode(tree: IndexDoc): DocumentNode {
    const mappedDoc = new DocumentNode();
    mappedDoc.name = tree.name;
    mappedDoc.availableUpdate = tree.availableUpdate;
    mappedDoc.selectionCount = 0;
    mappedDoc.size = tree.size;
    mappedDoc.title = tree.title;
    mappedDoc.models = tree.models;
    mappedDoc.idFile = tree.idFile;
    mappedDoc.idManual = tree.idManual;
    mappedDoc.type = tree.type;
    return mappedDoc;
  }

  private AddDms(data: IndexRoot): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data.dms) {
        this.dmService.clear().subscribe(() => {
          const mappedDms: Dm[] = [];
          data.dms.forEach((doc: IndexDm) => {
            mappedDms.push(this.mapIndexDm(doc));
          });
          if (mappedDms.length > 0) {
            this.dmService.addBulk(mappedDms).subscribe((result: boolean) => {
              observer.next(result);
              observer.complete();
            });
          } else {
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private mapIndexDm(tree: IndexDm): Dm {
    const mappedDoc = new Dm();
    mappedDoc.name = tree.name;
    mappedDoc.code = tree.code;
    mappedDoc.title = tree.title;
    mappedDoc.momodel = tree.momodel;
    mappedDoc.idDm = tree.idDm;
    mappedDoc.idFile = tree.idFile;
    mappedDoc.idManual = tree.idManual;
    mappedDoc.s1000d = tree.s1000d;
    return mappedDoc;
  }

  private AddIcns(data: IndexRoot): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data.icns) {
        this.icnService.clear().subscribe(() => {
          const mappedIcns: Icn[] = [];
          data.icns.forEach((doc: IndexIcn) => {
            if (mappedIcns.findIndex(icn => icn.name.toLowerCase() === doc.name.toLowerCase()) === -1) {
              mappedIcns.push(this.mapIndexIcn(doc));
            }
          });
          if (mappedIcns.length > 0) {
            this.icnService.addBulk(mappedIcns).subscribe((result: boolean) => {
              observer.next(result);
              observer.complete();
            });
          } else {
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private mapIndexIcn(tree: IndexIcn): Icn {
    const mappedDoc = new Icn();
    mappedDoc.name = tree.name;
    mappedDoc.type = tree.type;
    mappedDoc.availableUpdate = tree.availableUpdate;
    mappedDoc.size = tree.size;
    return mappedDoc;
  }

  private AddPmcs(data: IndexRoot): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (data.pms) {
        this.pmcService.clear().subscribe(() => {
          const mappedPmcs: Pmc[] = [];
          data.pms.forEach((doc: IndexPm) => {
            mappedPmcs.push(this.mapIndexPmc(doc));
          });
          if (mappedPmcs.length > 0) {
            this.pmcService.addBulk(mappedPmcs).subscribe((result: boolean) => {
              observer.next(result);
              observer.complete();
            });
          } else {
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private mapIndexPmc(tree: IndexPm): Pmc {
    const mappedDoc = new Pmc();
    mappedDoc.pubCode = tree.pubCode;
    mappedDoc.pubName = tree.pubName;
    mappedDoc.directory = tree.directory;
    mappedDoc.titlePage = tree.titlePage;
    mappedDoc.icns = [];
    tree.icns.forEach((icn) => {
      mappedDoc.icns.push(this.mapIndexPmIcn(icn));
    });
    return mappedDoc;
  }

  private mapIndexPmIcn(icn: IndexPmIcn): PmIcn {
    const mappedIcn = new PmIcn();
    mappedIcn.icn = icn.icn;
    return mappedIcn;
  }
  //#endregion Initial index ends

  //#region Sync Content
  private downloadContent(trees: DocumentTree[], updatableDocuments: string[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.downloadFileStatuses = [];
      this.downloadDocStatuses = [];
      this.offlineStatusService.getMappedDocumentAndIcns(trees, updatableDocuments, this.allDocuments,
        this.downloadFileStatuses, this.downloadDocStatuses,
        this.allDms, this.allPms, this.allIcns);
      if (this.downloadFileStatuses.length > 0) {
        this.totalCalls = this.totalCalls + this.downloadFileStatuses.length;
        const allDownloads$: Observable<DownloadFileStatus>[] = [];
        this.downloadFileStatuses.forEach(dfs => {
          allDownloads$.push(this.downloadResource(trees, dfs));
        });
        forkJoin(allDownloads$).subscribe((dfses: DownloadFileStatus[]) => {
          this.final1000DDocumentDownloadCheck(trees);
          const errorDocs = this.downloadDocStatuses.filter(d => d.isComplete === false).map(d => d.document);
          if (errorDocs && errorDocs.length > 0 && !this.cancelUpdateStarted) {
            this.errorDownloadingDoc$.next(errorDocs);
          }
          observer.next(true);
          observer.complete();
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private downloadResource(trees: DocumentTree[], currentDoc: DownloadFileStatus): Observable<DownloadFileStatus> {
    return new Observable((observer: Observer<DownloadFileStatus>) => {
      if (currentDoc.isIcn) {
        this.downloadIcn(currentDoc).subscribe({
          next: (dr) => {
            currentDoc.isComplete = true;
            this.setIcnDownloadDocComplete(trees, currentDoc);
            this.currentCalls = this.currentCalls + 1;
            observer.next(currentDoc);
            observer.complete();
          },
          error: (err) => {
            this.currentCalls = this.currentCalls + 1;
            console.log(err);
            observer.next(currentDoc);
            observer.complete();
          },
          complete: () => {
            observer.next(currentDoc);
            observer.complete();
          }
        });
      } else {
        this.downloadDoc(currentDoc).subscribe({
          next: (dr) => {
            currentDoc.isComplete = true;
            this.setDownloadDocComplete(trees, currentDoc);
            this.currentCalls = this.currentCalls + 1;
            observer.next(currentDoc);
            observer.complete();
          },
          error: (err) => {
            this.currentCalls = this.currentCalls + 1;
            console.log(err);
            observer.next(currentDoc);
            observer.complete();
          },
          complete: () => {
            observer.next(currentDoc);
            observer.complete();
          }
        });
      }
    });
  }

  private downloadDoc(downloadFileStatus: DownloadFileStatus): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (downloadFileStatus.isUpdate) {
        this.offlineZipDownloadService.getDoc(downloadFileStatus.document.name, downloadFileStatus).subscribe({
          next: (data) => {
            observer.next(data);
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          },
          complete: () => {
            observer.complete();
          }
        });
      } else {
          this.offlineFileService.isExists(`\\zip\\`, `${downloadFileStatus.document.name}.zip`).subscribe(exists => {
            if (exists) {
              observer.next(true);
              observer.complete();
            } else {
              this.offlineZipDownloadService.getDoc(downloadFileStatus.document.name, downloadFileStatus).subscribe({
                next: (data) => {
                  observer.next(data);
                  observer.complete();
                },
                error: (error) => {
                  observer.error(error);
                  observer.complete();
                },
                complete: () => {
                  observer.complete();
                }
              });
            }
          })
      }
    });
  }

  private downloadIcn(downloadFileStatus: DownloadFileStatus): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (!downloadFileStatus.icn.selectionCount) {
        downloadFileStatus.icn.selectionCount = 0;
      }
      if (downloadFileStatus.isUpdate) {
        this.offlineZipDownloadService.getResource(downloadFileStatus.icn.name).subscribe({
          next: data => {
            observer.next(true);
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          },
          complete: () => {
            if (!observer.closed) {
              observer.complete();
            }
          }
        });
      } else {
        if (downloadFileStatus.icn.selectionCount === 0) {
          this.offlineFileService.isExists(`\\zip\\`, `${downloadFileStatus.icn.name}.zip`).subscribe(exists => {
            if (exists) {
              observer.next(true);
              observer.complete();
            } else {
              this.offlineZipDownloadService.getResource(downloadFileStatus.icn.name).subscribe({
                next: data => {
                  observer.next(true);
                  observer.complete();
                },
                error: (error) => {
                  observer.error(error);
                  observer.complete();
                },
                complete: () => {
                  if (!observer.closed) {
                    observer.complete();
                  }
                }
              });
            }
          });
        } else {
          observer.next(true);
          observer.complete();
        }
      }
    });
  }

  private final1000DDocumentDownloadCheck(trees: DocumentTree[]) {
    const completedS1000Docs = this.downloadFileStatuses.filter(dfs => dfs.isS1000D === true && dfs.isComplete === true);
    if (completedS1000Docs.length > 0) {
      completedS1000Docs.forEach(d => {
        this.setS1000DocComplete(trees, d, true);
      });
    }
  }

  private setDownloadDocComplete(trees: DocumentTree[], currentDoc: DownloadFileStatus) {
    if (currentDoc.document.type === DocType.LEGACY) {
      this.setDocumentComplete(trees, currentDoc);
      this.offlineStatusService.sendResetOfflineCount(this.offlineCount, currentDoc.size, 1, 0, false, false, currentDoc.isUpdate);
    } else {
      this.setS1000DocComplete(trees, currentDoc, false);
    }
  }

  private setS1000DocComplete(trees: DocumentTree[], currentDoc: DownloadFileStatus, isFinalCheck: boolean) {
    const downloadDocStatus = this.downloadDocStatuses.find(d => d.documentName === currentDoc.fileName);
    if (downloadDocStatus) {
      const isAllIcnDownloaded = downloadDocStatus.downloadIcnStatuses.filter(icn => icn.isComplete === false).length === 0;
      if (isAllIcnDownloaded) {
        this.setDocumentComplete(trees, currentDoc);
        if (!isFinalCheck) {
          this.offlineStatusService.sendResetOfflineCount(this.offlineCount, currentDoc.size, 1, 0, false, true, currentDoc.isUpdate);
        }
      } else {
        if (!isFinalCheck) {
          this.offlineStatusService.sendResetOfflineCount(this.offlineCount, currentDoc.size, 0, 0, false, false, currentDoc.isUpdate);
        }
      }
    }
  }

  private setIcnDownloadDocComplete(trees: DocumentTree[], downloadFile: DownloadFileStatus) {
    const sharedDocs: DownloadDocStatus[] = [];
    const s1000DDocs = this.downloadDocStatuses.filter(d => d.isIcn === true);
    let downloadCompletedDocs = 0;
    s1000DDocs.forEach(d => {
      const currentIcn = d.downloadIcnStatuses.find(icn => icn.icnName === downloadFile.fileName);
      if (currentIcn) {
        currentIcn.isComplete = true;
        sharedDocs.push(d);
        const isAllIcnCompleted = d.downloadIcnStatuses.filter(dc => dc.isComplete === false).length === 0;
        if (isAllIcnCompleted) {
          this.setDocumentComplete(trees, downloadFile);
          d.isComplete = true;
          downloadCompletedDocs += 1;
        }
      }
    });

    if (sharedDocs.length > 0) {
      if (!downloadFile.icn.selectedBy) {
        downloadFile.icn.selectedBy = [];
      }

      sharedDocs.forEach(sd => {
        if (!downloadFile.icn.selectedBy.includes(sd.documentName)) {
          downloadFile.icn.selectedBy.push(sd.documentName);
        }
      });
      downloadFile.icn.selectionCount = downloadFile.icn.selectedBy.length;
      downloadFile.icn.lastUpdate = getNowUTC(new Date());
    }
    this.offlineStatusService.sendResetOfflineCount(this.offlineCount, downloadFile.size, downloadCompletedDocs, 0, false
      , true, downloadFile.isUpdate);
  }

  private setDocumentComplete(trees: DocumentTree[], documentFileStatus: DownloadFileStatus) {
    const isCompleted = this.completedDocsInSync.indexOf(documentFileStatus.document.name) > -1;
    if (!isCompleted) {
      const totalDocsSelected = trees.filter(t => t.doc === documentFileStatus.document.name);
      documentFileStatus.document.selectionCount += totalDocsSelected.length;
      documentFileStatus.document.lastUpdate = getNowUTC(new Date());
      totalDocsSelected.forEach(t => {
        t.usable = 'true';
      });
      const downloadDoc = this.downloadDocStatuses.find(d => d.documentName === documentFileStatus.document.name);
      downloadDoc.isComplete = true;
      this.completedDocsInSync.push(documentFileStatus.document.name);
    }

  }

  private removeContent(removedTrees: DocumentTree[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.removeFileStatuses = [];
      this.removeDocStatuses = [];
      this.offlineStatusService.getMappedDocumentAndIcns(removedTrees, [], this.allDocuments, this.removeFileStatuses,
        this.removeDocStatuses, this.allDms, this.allPms, this.allIcns);
      if (this.removeDocStatuses.length > 0) {
        this.totalCalls = this.totalCalls + this.removeDocStatuses.length;
        const allRemove$: Observable<DownloadDocStatus>[] = [];
        this.removeDocStatuses.forEach(dfs => {
          allRemove$.push(this.removeResource(removedTrees, dfs));
        });
        forkJoin(allRemove$).subscribe((dfses: DownloadDocStatus[]) => {
          if (this.removeDocStatuses.length === dfses.length) {
            observer.next(true);
            observer.complete();
          }
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private removeResource(trees: DocumentTree[], currentDoc: DownloadDocStatus): Observable<DownloadDocStatus> {
    return new Observable((observer: Observer<DownloadDocStatus>) => {
      if (!this.cancelUpdateStarted) {
        if (currentDoc.downloadIcnStatuses.length > 0) {
          currentDoc.downloadIcnStatuses.forEach(ricn => {
            this.deleteIcn(ricn.icnName);
            ricn.isComplete = true;
          });
          this.deleteDoc(trees, currentDoc);
        } else {
          this.deleteDoc(trees, currentDoc);
        }
        currentDoc.isComplete = true;
        this.currentCalls = this.currentCalls + 1;
        observer.next(currentDoc);
        observer.complete();
      }
    });
  }

  private deleteDoc(trees: DocumentTree[], currentDoc: DownloadDocStatus) {
    const currentFileStatus = this.removeFileStatuses.find(f => f.fileName === currentDoc.documentName);
    if (currentFileStatus) {
      const totalSharedDocs = this.allDocumentTrees.filter(t => t.doc === currentDoc.documentName && t.usable === 'true');
      let totalSharedDocsCount = totalSharedDocs.length;
      const sharedDocs = trees.filter(t => t.doc === currentDoc.documentName);
      totalSharedDocs.forEach(tsd => {
        if (sharedDocs.filter(sd => sd.path === tsd.path).length > 0) {
          totalSharedDocsCount = totalSharedDocsCount - 1;
        }
      });
      currentFileStatus.document.selectionCount = totalSharedDocsCount;
      if (currentFileStatus.document.selectionCount < 0) {
        currentFileStatus.document.selectionCount = 0;
      }
      // shared docs marked for deletion should be marked as unusable, but not deleted
      if (sharedDocs && sharedDocs.length > 0) {
        sharedDocs.forEach(t => {
          t.usable = 'false';
        });
      }
      if (currentFileStatus.document.selectionCount === 0) {
        this.deleteFile(currentDoc.documentName);
        this.offlineStatusService.sendResetOfflineCount(this.offlineCount, 0, 0, 1, false, false, false);
      } else {
        this.offlineStatusService.sendResetOfflineCount(this.offlineCount, 0, 0, 1, true, false, false);
      }
      currentDoc.isComplete = true;
    }
  }

  private deleteIcn(icnName: string) {
    const currentFileStatus = this.removeFileStatuses.find(f => f.fileName === icnName);
    if (currentFileStatus) {
      if (currentFileStatus.icn.selectedBy !== undefined) {
        const totalSharedDocs = this.allDocumentTrees.filter(t => t.doc === currentFileStatus.document.name && t.usable === 'true');
        const sharedRemovedDocs = this.removeDocStatuses.filter(rds => currentFileStatus.icn.selectedBy.indexOf(rds.documentName) > -1);
        sharedRemovedDocs.forEach(sd => {
          const index = currentFileStatus.icn.selectedBy.indexOf(sd.documentName);
          if (index > -1) {
            if (!totalSharedDocs.find(sd => sd.remove === 'false')) {
              currentFileStatus.icn.selectedBy.splice(index, 1);
            }
          }
        });
        currentFileStatus.icn.selectionCount = currentFileStatus.icn.selectedBy.length;
        if (currentFileStatus.icn.selectionCount === 0) {
          this.deleteFile(icnName);
        }
      }
    }
  }

  private getChangedDocsAndIcns() {
    this.downloadFileStatuses.forEach(fs => {
      if (fs.isIcn) {
        this.icns.push(fs.icn);
      } else {
        this.documentNodes.push(fs.document);
      }
    });

    this.removeFileStatuses.forEach(fs => {
      if (fs.isIcn) {
        this.icns.push(fs.icn);
      } else {
        this.documentNodes.push(fs.document);
      }
    });
  }

  private deleteFile(fileName: string) {
    this.offlineFileService.deleteFile(`\\zip\\`, `${fileName}.zip`).subscribe();
  }

  //#endregion Sync Content
}
