import { Injectable } from '@angular/core';
import { OfflineFileService } from './offline-file.service';
import { Observable, Observer, Subject, zip } from 'rxjs';
import { DataService } from '../data-services/data.service';
import { OfflineStatusService } from './offline-status.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineZipDownloadService {

  public fileDownloading;
  public additionalFilesDownloading;
  public errorDownloadingDoc$ = new Subject<any>();

  constructor(
    private dataService: DataService,
    private offlineFileService: OfflineFileService,
    public offlineStatusService: OfflineStatusService,
  ) { }

  getIndexes(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.dataService.getIndexes().subscribe((fileContent) => {
        if (fileContent) {
          this.writeZip('\\zip\\', 'indexes.zip', fileContent, observer);
        } else {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }

  getFaultCodes(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.dataService.getFaultCodes().subscribe((fileContent) => {
        if (fileContent) {
          this.writeZip(`\\zip\\`, 'faultcodes.zip', fileContent, observer);
        } else {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }

  getWiringData(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.dataService.getWiringData().subscribe((fileContent) => {
        if (fileContent) {
          this.writeZip(`\\zip\\`, 'wiringdata.zip', fileContent, observer);
        } else {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }

  getFleet(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.dataService.getFleet().subscribe((fileContent) => {
        if (fileContent) {
          this.writeZip(`\\zip\\`, 'fleet.zip', fileContent, observer);
        } else {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }

  getToc(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.dataService.getToc().subscribe((fileContent) => {
        if (fileContent) {
          this.writeZip(`\\zip\\`, 'toc.zip', fileContent, observer);
        } else {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }

  getDoc(fileName: string, doc): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.fileDownloading = this.dataService.getDoc(fileName).subscribe({
        next: (fileContent) => {
          if (fileContent) {
            this.writeZip(`\\zip\\`, `${fileName}.zip`, fileContent, observer);
          } else {
            observer.error(fileName + 'file content is empty or undefined');
            observer.complete();
          }
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        }
      });
    });
  }

  getResource(resourceName: string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.additionalFilesDownloading = this.dataService.getResource(resourceName).subscribe({
        next: (fileContent) => {
          if (fileContent) {
            this.writeZip(`\\zip\\`, `${resourceName}.zip`, fileContent, observer);
          } else {
            observer.error(resourceName + 'file content is empty or undefined');
          }
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  writeZip(folder: string, zipName: string, fileContent: Blob, observer: Observer<boolean>) {
    this.offlineFileService.writeFile(folder, zipName, fileContent).subscribe(result => {
      observer.next(result);
    }, err => {
      observer.error(err)
    }, () => {
      if (!observer.closed) {
        observer.complete();
      }
    });
  }
}
