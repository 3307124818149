import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiUriService {

  CCode = {
    get: '/ccode'
  };

  Model = {
    Toc: {
      get: '/toc/model/'
    }
  };

  Manual = {
    Toc: {
      get: '/toc/model/',
      getAll: '/manual/all'
    }
  };

  Ietm = {
    Toc: {
      sns: {
        get: '/toc/sns/',
      },
      manual: {
        get: '/toc/ietm/',
      }
    },
    get: '/ietm/',
    fault: {
      get: '/fault/'
    },
    icnloc: {
      get: '/icnloc/'
    },
    three: {
      get: (model: string, filetype: string, obj: string) => {
        return `/3d/${model}/${filetype}/${obj}`;
      }
    },
    threeRefdes: {
      get: '/3d/refdes/'
    }
  };

  User = {
    SessionInfo: {
      get: '/sessioninfo'
    }
  };

  Publication = {
    get: '/publication/'
  };

  Status = {
    get: '/status/revision',
    getByModel: (model: string) => {return `/status/revision/${model}`;}
  };

  Search = {
    get: '/search?keyword=',
    FullText: {
      get: (filename: string, page: string) => {
        return `/fulltext/${filename}?page=${page}`;
      }
    },
  };

  Metadata = {
    get: '/metadata/'
  };

  Ipb = {
    get: '/ipb/'
  };

  Icn = {
    get: '/icn/'
  };

  Png = {
    get: (filename: string, path: string, page: string) => {
      return `/png/${filename}?path=${path}&p=${page}`;
    }
  };

  Wire = {
    Data: {
      get: '/wire/data/'
    },
    Group: {
      get: '/wire/group/'
    },
    Param: {
      get: '/wire/param/'
    },
    Tracing: {
      get: '/wire/tracing/'
    },
    PinUid: {
      get: '/pinuid/'
    },
    Report: {
      get: '/wire/report/'
    },
  };

  Download = {
    PDF: {
      get: '/pdf/'
    },
    File: {
      get: '/file/'
    },
    PartsList: {
      get: (partsListName, filetype) => {
        return `/partslist/${partsListName}/export/${filetype}`;
      }
    }
  };

  Index = {
    get: '/indexes'
  };

  Zip = {
    Indexes: {
      get: '/zip/indexes'
    },
    FaultCodes: {
      get: '/zip/faultcodes'
    },
    WiringData: {
      get: '/zip/wiringdata'
    },
    Fleet: {
      get: '/zip/fleet'
    },
    Toc: {
      get: '/zip/toc'
    },
    ZipDoc: {
      get: '/zip/doc/'
    },
    ZipResource: {
      get: '/zip/resource/'
    }
  };

  News = {
    get: '/announcements/news'
  };

  Banner = {
    get: '/announcements/banner'
  };

  PartsLists = {
    base: '/partslist'
  };
}
