export type PlatformMethodImplementation = {
  electron?: {
    send: string,
    listen?: string
  },
  maui?: string
}

export enum PlatformMethod {
  login = 'login',
  logout = 'logout',
  checkInstanceUpdateStatus = 'checkInstanceUpdateStatus',
  setInstanceUpdateStatus = 'setInstanceUpdateStatus',
  getFreeSpace = 'getFreeSpace',
  getIcn = 'getIcn',
  getModelFilters = 'getModelFilters',
  logError = 'logError',
  makeHttpGetRequest = 'makeHttpGetRequest',
  printDocument = 'printDocument',
  saveAsPdf = 'saveAsPdf'
}


export const platformMethodImplementations: {[key in PlatformMethod]: PlatformMethodImplementation} = {
  login: {
    electron: {
      send: 'nodeless-login'
    },
    maui: 'Authenticate'
  },
  logout: {
    maui: 'SignOut'
  },
  checkInstanceUpdateStatus: {
    electron: {
      send: 'api-get-is-update-triggered',
      listen: 'api-get-is-update-triggered-response'
    },
    maui: 'CheckInstanceUpdateStatus'
  },
  setInstanceUpdateStatus: {
    electron: {
      send: 'api-set-is-update-triggered'
    },
    maui: 'SetInstanceUpdateStatus'
  },
  getFreeSpace: {
    electron: {
      send: 'api-get-free-space',
      listen: 'api-get-free-space-response'
    },
    maui: 'GetFreeSpace'
  },
  getIcn: {
    electron: {
      send: 'getIcn',
      listen: 'getIcnResult'
    },
    maui: 'GetIcn'
  },
  getModelFilters: {
    electron: {
      send: 'getModelFilters',
      listen: 'getModelFiltersResult'
    }
  },
  logError: {
    electron: {
      send: 'error-log'
    },
    maui: 'LogWebViewError'
  },
  makeHttpGetRequest: {
    maui: 'MakeHttpGetRequest'
  },
  printDocument: {
    maui: 'PrintDocument'
  },
  saveAsPdf: {
    maui: 'SaveAsPdf'
  }
}
