import { Injectable } from '@angular/core';
import { OfflineFeedService } from './offline-feed.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { OfflineStates } from '../../enums/offline-states';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})

export class OfflineService {
  isOfflineCapable = false;
  isLibraryActivated = false;
  shouldHideOffline = false; // TODO: remove for ios release 2
  state: OfflineStates = OfflineStates.UP_TO_DATE;
  updateProgress = 0;

  private _offlineEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _stateEvent: BehaviorSubject<OfflineStates> = new BehaviorSubject<OfflineStates>(null);
  public offlineEvent$: Observable<boolean> = this._offlineEvent.asObservable();
  public stateEvent$: Observable<string> = this._stateEvent.asObservable();

  get isOffline(): boolean {
    return sessionStorage.getItem('isOffline') === 'true';
  }

  set isOffline(isOffline: boolean) {
    sessionStorage.setItem('isOffline', `${isOffline}`);
  }

  get offlineEvent(): BehaviorSubject<boolean> {
    return this._offlineEvent;
  }

  get stateEvent(): BehaviorSubject<OfflineStates> {
    return this._stateEvent;
  }

  constructor(
    private offlineFeedService: OfflineFeedService,
    private platformService: PlatformService
  ) {
    this._stateEvent.next(this.state);
    this._offlineEvent.next(this.isOffline);
    this.isOfflineCapable = platformService.isOfflineCapable();
    this.offlineFeedService.isActivated().subscribe(result => this.isLibraryActivated = result);
    this.shouldHideOffline = platformService.shouldHideOffline();
  }

  changeIsOffline(isOffline: boolean): void {
    this.isOffline = isOffline;
    this._offlineEvent.next(isOffline);
  }

  setstateEvent(state: OfflineStates) {
    this.state = state;
    this._stateEvent.next(this.state);
  }

  setUpdateProgress(progress: number) {
    this.updateProgress = progress;
  }
}
