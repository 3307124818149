<ng-container>
  <bell-header>
    <ng-content></ng-content>
  </bell-header>
  <bell-banner *ngIf="!offlineService.isOffline">
    <ng-content></ng-content>
  </bell-banner>
  <div class="body-content">
    <bell-side-nav>
      <ng-content></ng-content>
    </bell-side-nav>
    <bell-main>
      <router-outlet></router-outlet>
    </bell-main>
    <bell-side-nav-buttons *ngIf="!offlineService.isOffline" [isMobile]="true">
      <ng-content></ng-content>
    </bell-side-nav-buttons>
  </div>
  <bell-footer>
    <ng-content></ng-content>
  </bell-footer>
</ng-container>